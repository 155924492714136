import React from 'react';
import './ContactMe.css'; 

const PricingCard = ({ title, price, features }) => (
  <div className="pricing-card">
    <h3 className="pricing-title">{title}</h3>
    <p className="pricing-price">{price}€/HT</p>
    <ul className="pricing-features">
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
     
  </div>
);

// <button className="pricing-button">Choisir</button> à mettre entre ul et la div si besoin
const Pricing = () => {
  const pricingData = [
    {
      title: "Site Vitrine",
      price: 450,
      features: [
        "Design personnalisé",
        "Jusqu'à 5 pages",
        "Responsive design",
        "Formulaire de contact et réservation"
      ]
    },
    {
      title: "E-commerce",
      price: 990,
      features: [
        "Jusqu'à 100 produits",
        "Système de paiement sécurisé",
        "Gestion des stocks",
        "Support client"
      ]
    },
    {
      title: "Référencement SEO",
      price: 200,
      features: [
        "Audit SEO complet",
        "Optimisation on-page",
        "Création de contenu",
      ]
    }
  ];

  return (
    <section id="pricing" className="pricing-section">
      <h2 className="pricing-header">Mes Tarifs</h2>
      <div className="pricing-container">
        {pricingData.map((plan, index) => (
          <PricingCard key={index} {...plan} />
        ))}
      </div>
    </section>
  );
};

export default Pricing;